import React from "react"
import PropTypes from "prop-types"
import {Link} from 'react-router-dom';

const KEY_DARK_MODE = "edgar.api.darkMode";

export default class ApiMenu extends React.Component {

    initialDarkMode = "true" === localStorage.getItem(KEY_DARK_MODE);

    constructor(props) {
        super(props);
        this.state = {
            darkMode: this.initialDarkMode
        };
    }

    static propTypes = {
        specSelectors: PropTypes.object.isRequired,
        specActions: PropTypes.object.isRequired,
        oas3Actions: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
        oas3Selectors: PropTypes.func.isRequired,
        layoutSelectors: PropTypes.object.isRequired,
        layoutActions: PropTypes.object.isRequired,
        authActions: PropTypes.object.isRequired,
        authSelectors: PropTypes.object.isRequired,
        getConfigs: PropTypes.func.isRequired,
        fn: PropTypes.func.isRequired
    }

    clickSlider(){
        this.state.darkMode = !this.state.darkMode;

        // update local storage
        localStorage.setItem(KEY_DARK_MODE, this.state.darkMode);

        if(this.state.darkMode){
            document.getElementById('edgarLogo').className = 'edgar-logo-white';
        }
        else {
            document.getElementById('edgarLogo').className = 'edgar-logo-black';
        }
        this.props.onDarkModeChanged(this.state.darkMode);

        this.setState({darkMode: this.state.darkMode});
    }

    render() {
        let {
            specSelectors,
            onClick
        } = this.props

        const taggedOps = specSelectors.taggedOperations()

        const publicArray = [];
        const internalArray = [];

        taggedOps.forEach((tagObj, tag) => {
            let details = specSelectors.tagDetails(tag);
            if (details != null) {
                if ("true" === details.get("x-internal")) {
                    internalArray.push(this.renderOperationsMenuItem(tagObj, tag));
                    return;
                }
            }
            publicArray.push(this.renderOperationsMenuItem(tagObj, tag));
        })

        let logoClassName = "edgar-logo-black";
        if (this.state.darkMode) {
            logoClassName = "edgar-logo-white";
        }

        return (
            <div>
                <div className={"api-menu-header-parent"}>
                    <span id="edgarLogo" className={logoClassName}></span>
                    <span className="api-menu-header">
                        EDGAR API Development Toolkit
                    </span>
                    <label id="style-switch" className="switch">
                        <input type="checkbox" id="darkModeSlider" checked={this.state.darkMode}/>
                        <span className="slider round" onClick={() => this.clickSlider()}></span>
                    </label>
                </div>
                <div className={['api-operation-menu-section']}>
                    <div className={"api-menu-tag api-flex-container"}>
                        <div className={"api-flex-menu-full"}><a href={"/#overview"}>Overview</a></div>
                    </div>
                </div>
                <div className={['api-operation-menu-section']}>
                    <div className={"api-menu-tag api-flex-container"}>
                        <div className={"api-flex-menu-full"}><a href={"/#tokens"}>Tokens</a></div>
                    </div>
                </div>
                <div className={['api-operation-menu-section']}>
                    <div className={"api-menu-tag api-flex-container"}>
                        <div className={"api-flex-menu-full"}><a href={"/#authentication"}>Authentication</a></div>
                    </div>
                </div>
                <div className={['api-operation-menu-section']}>
                    <div className={"api-menu-tag api-flex-container"}>
                        <div className={"api-flex-menu-full"}><a href={"/#communication"}>Communication</a></div>
                    </div>
                </div>
                <div className={['api-operation-menu-section']}>
                    <div className={"api-menu-tag api-flex-container"}>
                        <div className={"api-flex-menu-full"}><a href={"/#endpoints"}>Server Endpoints</a></div>
                    </div>
                </div>
                {publicArray}
                {internalArray}
                <div className={['api-operation-menu-section']}>
                    <div className={"api-menu-tag api-flex-container"}>
                        <div className={"api-flex-menu-full"}><Link to="/resources">Resources</Link></div>
                    </div>
                </div>
            </div>
        )
    }

    renderOperationsMenuItem = (tagObj, tag) => {
        const {
            specSelectors,
            getComponent,
            oas3Selectors,
            layoutSelectors,
            layoutActions,
            getConfigs,
        } = this.props
        let {
            operations
        } = tagObj.toJS();
        let internal = false;
        let details = specSelectors.tagDetails(tag);
        if (details != null) {
            if ("true" === details.get("x-internal")) {
                internal = true;
            }
        }

        return (
           <div key={"menu-" + tag} className={['api-operation-menu-section']}>
                <div className={"api-menu-tag api-flex-container"}>
                    <div className={internal ? "api-flex-left" : "api-flex-menu-full"}>{tag}</div>
                    { internal ? <div className={"api-flex-right"}><div className={"badge--warning"}>internal</div></div> : null }
                </div>
                <div className={"api-menu-options"} style={{display: "none"}}>
                    { operations.map(this.renderOperationMenuItem) }
                </div>
           </div>
        )
    }

    renderOperationMenuItem = (operationObject) => {
        // bugfix: operationId changes after its associated opblock-summary is expanded, this ensures we always maintain the same value
        let pText = operationObject.operation.__originalOperationId || operationObject.operation.operationId;
        if (pText === null || pText === "" || pText === undefined) {
            pText = operationObject.path;
        }
        // use the operation id (because it exists)
        return (
            <div key={"menu-operation-" + operationObject.id} id={"side-menu-" + operationObject.id} className={['api-menu-operation']}
                onClick={ ()=> {
                        document.getElementById("side-menu-link-" + operationObject.id).click();
                    }
                }>
                <a id={"side-menu-link-" + operationObject.id} href={"/#" + pText}>{pText}</a>
            </div>
        )
    }

    //TODO: moving this logic into the swagger-ui for faster turn-around on the bugfix, need to refactor and parameterize this component.
    // componentDidMount(){
    //     setTimeout(() => {
    //         let menuItem = document.getElementsByClassName("api-menu-tag");
    //         let i;

    //         for (i = 0; i < menuItem.length; i++) {
    //             menuItem[i].addEventListener("click", function() {
    //                 this.classList.toggle("active");
    //                 let content = this.nextElementSibling;
    //                 if (content != null && content.style != null) {
    //                     if (content.style.display === "block") {
    //                         content.style.display = "none";
    //                     } else {
    //                         content.style.display = "block";
    //                     }
    //                 } else {
    //                     // clicking on the non-expandable leftnav menu mimicks clicking on the a link inside of it
    //                     const myLink = this.getElementsByTagName('a');
    //                     if (myLink.length>0 && myLink[0].hasAttribute('href')){
    //                         myLink[0].click();
    //                     }
    //                 }
    //             });
    //         }
    //     }, 500);
    // }
}

ApiMenu.propTypes = {
    layoutActions: PropTypes.object.isRequired,
    specSelectors: PropTypes.object.isRequired,
    specActions: PropTypes.object.isRequired,
    layoutSelectors: PropTypes.object.isRequired,
    getComponent: PropTypes.func.isRequired,
    fn: PropTypes.object.isRequired
}