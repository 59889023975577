import React from "react"
import PropTypes from "prop-types"
import {Buffer} from "buffer"

export default class ApiContent extends React.Component {

    static propTypes = {
        specSelectors: PropTypes.object.isRequired,
        specActions: PropTypes.object.isRequired,
        oas3Actions: PropTypes.object.isRequired,
        getComponent: PropTypes.func.isRequired,
        oas3Selectors: PropTypes.func.isRequired,
        layoutSelectors: PropTypes.object.isRequired,
        layoutActions: PropTypes.object.isRequired,
        authActions: PropTypes.object.isRequired,
        authSelectors: PropTypes.object.isRequired,
        getConfigs: PropTypes.func.isRequired,
        fn: PropTypes.func.isRequired
    }

    renderServers(servers) {
        if (servers === null) {
            return null;
        }

        const elements = new Array(servers.length);

        for (let idx = 0; idx < servers.size; idx++) {
            const server = servers.get(idx);
            const description = server.get('description');
            const url = server.get('url');
            elements.push(<tr><td>{description}</td><td>{url}</td></tr>)
        }

        return elements;
    }

    render() {
        let {
            specSelectors,
            getComponent
        } = this.props


        // get components
        const Operations = getComponent("operations", true)
        const AuthorizeBtnContainer = getComponent("AuthorizeBtnContainer", true)

        // decide if we actually have security/authentication information to show
        const hasSecurityDefinitions = !!specSelectors.securityDefinitions()
        const hasServers = !!specSelectors.servers();

        let statusUrl = null;
        if (hasServers) {
            statusUrl = specSelectors.servers().get(0).get("url") + "/status";
        }

        const SyntaxHighlighter = getComponent("SyntaxHighlighter", true);

        const jwtUserApiHeader =
`{
  "kid": "75c8d379-2d82-415e-a3c5-7501699e3101",
  "alg": "ECDH-ES",
  "userId": "0ebc7681-2b96-42a8-8e76-efd8c23b1b13",
  "expiresAt": "2024-08-24T03:00:00Z"
}`

        const jwtFilerApiHeader =
`{
  "cik": "0000000000",
  "kid": "75c8d379-2d82-415e-a3c5-7501699e3101",
  "alg": "ECDH-ES",
  "expiresAt": "2025-07-25T03:00:00Z"
}`

        // if you don't split this then it will overrun outside of the border and mess up the entire page style
        const jwtHeaderBase64 = Buffer.from(jwtFilerApiHeader, 'utf8').toString('base64');

        const httpRequest =
`GET /status HTTP/1.1
Authorization: bearer eyJjaWsiOiIwMDAxMzE2MDAzIiwia2lkIjoiOThiOTg0ZTk1NTk5Y2Y3YWNlMDRjYzk1N2U2OWE1MDIiLCJhbGciOiJFUzUxMiIsImV4cGlyZXNBdCI6IjIwMjUtMDctMjVUMDM6MDA6MDBaIiwidHlwIjoiSldUIn0.eyJKV1QiOiJFTkNSWVBURUQgVkFMVUUifQ.AeF5hGsaJnQQEZUk-OBfVUzf-nuu5zJSn9MSK5Y7eSlj0RiJ_7jKKUA4J11I61tfe5TOBxrnhwqMiWuzdl3gtJnyAIqBeF-G5_A93EUbI3ttW_pUXQupKsdmW1lDnHyS3ciGbCfkeXz28VWSufMLORqzq1wi-EzgpbT9cFrZR7YGMOj9
Accept: application/json`;

        const httpResponse =
`200 OK
Content-Type: application/json

{
  "tracking": "6345f99403f9c8014e4332a8cba2c5a6",
  "locator": "l33922",
  "message": "EDGAR is operating normally. All submissions will receive today’s filing date.",
  "condition": "ACCEPTING"
}`
        return (
            <div className={['constrained-view']}>
                <div className={['api-flex-container']}>
                    <div id={"overview"} className={['api-flex-full']}><h2>Overview</h2></div>
                    <div className={['api-flex-text-body']}>
                        <p>
                            The purpose of this documentation is to provide information for interacting with the APIs
                            provided by EDGAR. This collection of APIs provide the ability to check the EDGAR system
                            status,
                            manage users, submit EDGAR filings, and check the status of submitted filings.
                        </p>
                        <p>
                            These APIs are based on <a href={"https://en.wikipedia.org/wiki/REST"}>REST</a> semantics
                            with
                            resource names that are based on the verbs or actions that are being performed on discrete
                            resources within the system. In general this API uses JSON for request and response
                            communication
                            for most cases and binary or XML requests for filing submissions.
                        </p>
                        <p>
                            These APIs make use of standard HTTP semantics including various HTTP methods defined by
                            the&nbsp;
                            <a href={"https://httpwg.org/specs/rfc9110.html"}>HTTP RFC</a> as well as <a
                            href={"https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml"}>response
                            codes</a>&nbsp;
                            as maintained by the IANA.
                        </p>
                        <p>
                            The information provided here represents the views of the staff of the EDGAR Business
                            Office.
                            It is not a rule, regulation, or statement of the Securities and Exchange Commission ("the
                            Commission").
                            The Commission has neither approved nor disapproved its content. As with all staff
                            statements,
                            this information has no legal force or effect: it does not alter or amend the applicable
                            law,
                            and it creates no new or additional obligations on any person.
                        </p>
                    </div>
                </div>
                {/* realistically this will always be true */}
                {hasSecurityDefinitions ?
                    <div className={['api-flex-container']}>
                        <div id={"tokens"} className={['api-flex-full']}><h2>Tokens</h2></div>
                        <div className={['api-flex-text-body']}>
                            <p>
                                The EDGAR APIs make use of HTTP Bearer authentication with tokens provided by Filer
                                Management. There are two types of tokens: the Filer API Token and the User API Token
                                (collectively: API Tokens).
                                These tokens are issued by the Filer Management application. The Filer API Token is
                                generated by a Technical Admin
                                and should be provided to applications that require it for communication with EDGAR. The
                                User API Token is generated
                                by individuals that will be creating or submitting filings to EDGAR. The User API Token
                                should be provided to applications
                                that will make use of the token when communicating with EDGAR on behalf of the
                                individual.
                            </p>
                            <p>
                                The API Tokens are JWT tokens in JOSE format with an encrypted payload that
                                was signed before it was encrypted. For more information on the JWT format see
                                the <a href={"https://jwt.io/introduction"}>documentation at JWT.io</a>.
                            </p>
                            <p>
                                A JWT token is presented as a series of base64 stanzas separated by a "."
                                character. The first stanza, the header, is not encrypted and contains information that
                                may
                                be useful to
                                application or integration teams. The example JWT header, given below, is the first
                                stanza
                                from a sample
                                Filer API Token.
                            </p>
                            <div className={['highlight-code']}>
                                <SyntaxHighlighter
                                    language="base64"
                                    className="microlight"
                                    renderPlainText={({children, PlainTextViewer}) => (
                                        <PlainTextViewer className="curl">{children}</PlainTextViewer>
                                    )}
                                >
                                    {jwtHeaderBase64}
                                </SyntaxHighlighter>
                            </div>
                            <p>
                                When decoded this header contains some useful information for both
                                the EDGAR API and applications integrating with the EDGAR API.
                            </p>
                            <div className={['highlight-code']}>
                                <SyntaxHighlighter
                                    language="json"
                                    className="microlight"
                                    renderPlainText={({children, PlainTextViewer}) => (
                                        <PlainTextViewer className="curl">{children}</PlainTextViewer>
                                    )}
                                >
                                    {jwtFilerApiHeader}
                                </SyntaxHighlighter>
                            </div>
                            <p>
                                In the case of the Filer API Token you can see that it contains the key ID ("kid")
                                and the algorithm ("alg") used to encrypt the payload. This is required by
                                the server to decode the JWT provided to the EDGAR API.
                            </p>
                            <p>
                                In this header the CIK ("cik") and expires at ("expiresAt") fields are present in
                                addition
                                to the key ID and algorithm. The CIK is the CIK that the Filer API Token is assigned to
                                and
                                can be used by applications to ensure that the Filer API Token matches the organization
                                that is communicating with the EDGAR API.
                            </p>
                            <p>
                                The expires at ("expiresAt") field allows applications to check the expiration status of
                                a token without making a call to the EDGAR
                                API. This does not check if the token has been manually revoked but allows applications
                                to check the status of tokens locally.
                            </p>
                            <p>
                                The User API Token contains similar information in the same format. There is useful
                                information contained in the header stanza that can be used by applications to determine
                                information about the token.
                            </p>
                            <div className={['highlight-code']}>
                                <SyntaxHighlighter
                                    language="json"
                                    className="microlight"
                                    renderPlainText={({children, PlainTextViewer}) => (
                                        <PlainTextViewer className="curl">{children}</PlainTextViewer>
                                    )}
                                >
                                    {jwtUserApiHeader}
                                </SyntaxHighlighter>
                            </div>
                            <p>
                                The User ID is also contained within the header. This gives applications
                                the capability to uniquely identify the token. The expires at ("expiresAt") field
                                also gives applications the ability to determine if the token should still be valid
                                without calling the EDGAR API. This only checks for expiration and the token could
                                still be revoked within the EDGAR system.
                            </p>
                        </div>
                        <div id={"authentication"} className={['api-flex-left']}><h2>Authentication</h2></div>
                        <div className={['api-flex-right h2-match']}><AuthorizeBtnContainer/></div>
                        <div className={['api-flex-text-body']}>
                            <p>
                                In order to authenticate to the EDGAR APIs the token or tokens that will be used must be
                                added to the
                                "Authorization" header with the "bearer " prefix. For example: given a token like
                                'abc123' the contents
                                of the "Authorization" header would be "bearer abc123". In the event that two tokens are
                                being used they
                                should be separated by a "," or a " ". For example: given the Filer API Token "filer123"
                                and the User
                                API Token "user345" the contents of the "Authorization" header would be "bearer
                                filer123,user345".
                            </p>
                            <p>
                                The EDGAR system will calculate authorization to use API resources based on the tokens
                                provided. The CIK
                                represented by the Filer API Token and the individual represented by the User API token
                                are
                                used to determine
                                if the request can be completed by the system.
                            </p>
                            <p>
                                In the OpenAPI Specification that the EDGAR APIs use each of the two possibilities for
                                presenting
                                tokens is covered by a Security Scheme. Each API method uses either the "Filer API
                                Token" security scheme
                                or the "Filer API Token and User API Token" security scheme. Each of these schemes
                                expect a token
                                (or tokens) issued by the Filer Management application and presented in the way
                                described above.
                            </p>
                            <p>
                                To test APIs with Authorization you can click the <strong>Authorize</strong> button
                                above
                                or click the "lock" icon next to any API resource. This will allow you to set the
                                "bearer"
                                authentication for any requests that use the same security scheme.
                            </p>
                        </div>
                        {/* don't show this, there's no reason to show it but without it the authorization popup will not show */}

                    </div> : null}
                <div className={['api-flex-container']}>
                    <div id={"communication"} className={['api-flex-full']}><h2>Communication</h2></div>
                    <div className={['api-flex-text-body']}>
                        <p>
                            A typical request to an EDGAR API would be made over HTTP, supplying an API Token,
                            and would contain either JSON or XML contents. The following simple GET request
                            is to get the status of the EDGAR system.
                        </p>
                        <div className={['highlight-code']}>
                            <SyntaxHighlighter
                                language="http"
                                className="microlight"
                                renderPlainText={({children, PlainTextViewer}) => (
                                    <PlainTextViewer className="curl">{children}</PlainTextViewer>
                                )}
                            >
                                {httpRequest}
                            </SyntaxHighlighter>
                        </div>
                        <p>
                            A typical EDGAR API response will include an HTTP status code as well as a
                            response body. The HTTP status code is the primary indicator of the status of a response. In
                            general: a response in the 200-299 range is a "success" response, a response in the 400-499
                            range
                            represents that something is wrong with the request, and anything in the 500-599 range means
                            that something went wrong while processing the request.
                        </p>
                        <p>

                        </p>
                        <p>
                            The response body that returns from the API will include
                            a tracking ID ("tracking") that can be used when communicating with the Help Desk to identify
                            the particular request in question. The response will also include a property
                            called "locator" that can also assist the Help Desk in finding the record in the EDGAR
                            system.
                        </p>
                        <div className={['highlight-code']}>
                            <SyntaxHighlighter
                                language="http"
                                className="microlight"
                                renderPlainText={({children, PlainTextViewer}) => (
                                    <PlainTextViewer className="curl">{children}</PlainTextViewer>
                                )}
                            >
                                {httpResponse}
                            </SyntaxHighlighter>
                        </div>
                        <p>
                            In addition to the tracking ID and locator there are other elements that can be found among responses.
                            The
                            &nbsp;<strong>messages</strong> can contain information that can indicate further
                            information
                            about a response. These are intended to be supplemental to any HTTP code that is returned.
                        </p>
                    </div>
                </div>
                {/* this section will be present/rendered when servers are configured which is always */}
                {hasServers ?
                    <div className={['api-flex-container']}>
                        <div id={"endpoints"} className={['api-flex-full']}><h2>Server Endpoints</h2></div>
                        <div className={['api-flex-text-body']}>
                            <p>
                                The EDGAR API specification defines the specific server endpoints for communicating with
                                different services. The servers are specified independently to enable flexibility in the
                                future and should be considered as separate resources by implementors.
                            </p>
                            <div className={'server-table-container'}>
                                <table>
                                    <thead>
                                    <tr>
                                        <th><strong>Server Name</strong></th>
                                        <th><strong>Base URL</strong></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderServers(specSelectors.servers())}
                                    </tbody>
                                </table>
                            </div>
                            <p>
                                Each API resource path is relative to the server specified in the endpoint specification
                                for that resource. The full path to any resource is the path of the server and the path
                                of
                                the method combined. For example, to create the path to the Status resource of the EDGAR
                                Status API you would need the base url for the EDGAR Status API and the path to the
                                status resource.
                            </p>
                            <SyntaxHighlighter
                                language="http"
                                className="microlight"
                                renderPlainText={({children, PlainTextViewer}) => (
                                    <PlainTextViewer className="curl">{children}</PlainTextViewer>
                                )}
                            >
                                {statusUrl}
                            </SyntaxHighlighter>
                            <p>
                                This URL would be appropriate for a "get" request to determine the status
                                of the EDGAR system.
                            </p>

                        </div>
                    </div>
                    : null}
                <br/> {/* probably need to make better usage of padding here */}
                <Operations/>
            </div>
        )
    }
}
