import React from "react";
import {createRoot} from 'react-dom/client';
import "swagger-ui-react/swagger-ui.css";
import SwaggerContainer from "./components/api/swagger-ui";
import {BrowserRouter} from "react-router-dom";

const root = createRoot(
    document.getElementById('api-ui')
);
root.render(
    <BrowserRouter>
        <SwaggerContainer />
    </BrowserRouter>
);


