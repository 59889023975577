import React, {PureComponent} from "react"
import PropTypes from "prop-types"
import {Iterable, List} from "immutable"
import ImPropTypes from "react-immutable-proptypes"
import toString from "lodash/toString"
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class ApiOperationSummary extends PureComponent {

    static propTypes = {
        specPath: ImPropTypes.list.isRequired,
        operationProps: PropTypes.instanceOf(Iterable).isRequired,
        isShown: PropTypes.bool.isRequired,
        toggleShown: PropTypes.func.isRequired,
        getComponent: PropTypes.func.isRequired,
        authActions: PropTypes.object,
        authSelectors: PropTypes.object,
    }

    static defaultProps = {
        operationProps: null,
        specPath: List(),
        summary: ""
    }

    render() {

        let {
            isShown,
            toggleShown,
            getComponent,
            authActions,
            authSelectors,
            operationProps,
            specPath,
        } = this.props

        let {
            summary,
            isAuthorized,
            method,
            op,
            showSummary,
            path,
            operationId,
            originalOperationId,
            displayOperationId,
        } = operationProps.toJS()

        let {
            summary: resolvedSummary,
        } = op

        let security = operationProps.get("security")

        const AuthorizeOperationBtn = getComponent("authorizeOperationBtn", true)
        const OperationSummaryMethod = getComponent("OperationSummaryMethod")
        const OperationSummaryPath = getComponent("OperationSummaryPath")
        const JumpToPath = getComponent("JumpToPath", true)
        const CopyToClipboardBtn = getComponent("CopyToClipboardBtn", true)

        const hasSecurity = security && !!security.count()
        const securityIsOptional = hasSecurity && security.size === 1 && security.first().isEmpty()
        const allowAnonymous = !hasSecurity || securityIsOptional

        return (
            <div className={`opblock-summary opblock-summary-${method}`} id={operationId}>
                <button
                    aria-expanded={isShown}
                    className="opblock-summary-control"
                    onClick={toggleShown}
                >
                    <OperationSummaryMethod method={method} />
                    <div className="opblock-summary-path-description-wrapper">
                        <OperationSummaryPath getComponent={getComponent} operationProps={operationProps} specPath={specPath} />

                        {!showSummary ? null :
                            <div className="opblock-summary-description">
                                {toString(resolvedSummary || summary)}
                            </div>
                        }
                    </div>

                    {displayOperationId && (originalOperationId || operationId) ? <span className="opblock-summary-operation-id">{originalOperationId || operationId}</span> : null}
                </button>
                <CopyToClipboardBtn textToCopy={`${specPath.get(1)}`} />
                {
                    allowAnonymous ? null :
                        <AuthorizeOperationBtn
                            isAuthorized={isAuthorized}
                            onClick={() => {
                                const applicableDefinitions = authSelectors.definitionsForRequirements(security)
                                authActions.showDefinitions(applicableDefinitions)
                            }}
                        />
                }
                <JumpToPath path={specPath} />{/* TODO: use wrapComponents here, swagger-ui doesn't care about jumpToPath */}
                <button
                    aria-label={`${method} ${path.replace(/\//g, "\u200b/")}`}
                    className="opblock-control-arrow"
                    aria-expanded={isShown}
                    tabIndex="-1"
                    onClick={toggleShown}>
                    {isShown ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </button>
            </div>
        )
    }
}