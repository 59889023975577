import React from "react"
import {faFileDownload, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


export default class Resources extends React.Component {

    render(){
        return (
            <>
                <h2>Resources</h2>
                <h3>Resources for developers</h3>

                <p>In addition to API reference material, we offer a series of developer guides for the API, including:</p>

                <ul className="resources-list">
                    <li> <div className="link-style"><a href="openapi?format=json">EDGAR API Spec File <FontAwesomeIcon icon={faFileDownload}/></a></div> </li>
                    <ul>
                        <li>This is a computer readable JSON file with the OpenAPI v3 specification for the EDGAR APIs</li>
                    </ul>
                    <li><span className="link-style"><a href="edgar-api.pdf">API Toolkit PDF <FontAwesomeIcon icon={faFilePdf} /></a></span></li>
                    <ul>
                        <li>A PDF that contains the documentation for the EDGAR APIs.</li>
                    </ul>
                    <li><a className="link-style" href="sampleAppCode.zip" download="sampleAppCode.zip">Sample Filing Application <FontAwesomeIcon icon={faFileDownload} /></a></li>
                    <ul>
                        <li>A downloadable file with code from a working “sample” filing application that connects to the submission API, submission status API, and EDGAR operational status API.</li>
                    </ul>
                </ul>

            </>
        )
    }

}