// wrap plugin -> https://swagger.io/docs/open-source-tools/swagger-ui/customization/plugin-api/
import React from "react"


export default class ApiOperationTag extends React.Component {
    render() {
        // get the wrapped component
        const Wrapped = this.props.operationTag;

        // for now just return that component without modification
        const rendered = <Wrapped {...this.props.operationTagProps}/>;

        // get details about element
        const {
            tag,
            tagObj
        } = this.props.operationTagProps;


        let tagDescription = tagObj.getIn(["tagDetails", "description"], null)

        // from the details we can handle setting the layout and description

        // render only the children
        return <div>
            <div className={['api-flex-container']}>
                <div id={"tokens"} className={['api-flex-full']}><h2>{tag}</h2></div>
                <div className={['api-flex-text-body']}>
                    <p>{tagDescription}</p>
                </div>
            </div>
            <div>
                {rendered.props.children}
            </div>
        </div>
    }

}