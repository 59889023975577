import React, {Component} from "react";
import SwaggerUI from "swagger-ui-react";

// these components can wholesale override / extend pieces
import ApiOperations from "./custom-operations";
import SideMenu from "./side-menu";
import Resources from "./resources";

// some tags are imported as wrappers for existing tags
import ApiOperationTag from "./custom-operation-tag";
import ApiOperation from "./custom-operation";
import ApiOperationSummary from "./custom-operations-summary";
import ApiContent from "./api-content";
import ApiParameters from "./custom-parameters";
import ApiRequestBody from "./custom-request-body";

// import components required to do routing for ui
import {Route, Routes} from "react-router-dom";

const KEY_DARK_MODE = "edgar.api.darkMode";

class ApiBaseLayout extends React.Component {
    initialDarkMode = "true" === localStorage.getItem(KEY_DARK_MODE);

    constructor(props) {
        super(props);
        this.state = {
            darkMode: this.initialDarkMode
        };
    }

    handleClick = (darkMode) => {
        if(darkMode){
            document.body.classList.add('dark-mode');
            document.getElementById('parent-div').classList.add('dark-mode');
        }
        else {
            document.body.classList.remove('dark-mode');
            document.getElementById('parent-div').classList.remove('dark-mode');
        }
    }

    render() {
        const {
            getComponent
        } = this.props

        const Contents = getComponent("contents", true);
        const Resources = getComponent("Resources", true);
        const Menu = getComponent("Menu", true);

        let classNames  = "api-flex-parent";
        if (this.state.darkMode) {
            document.body.classList.add('dark-mode');
            classNames = classNames + " dark-mode";
        } else {
            document.body.classList.remove('dark-mode');
        }

        return (
            <div id="parent-div" className={classNames} >
                <div className={['api-side-menu']} >
                    <Menu onDarkModeChanged={this.handleClick}/>
                </div>

                <div className={['api-flex-body']}>
                    <Routes>
                        <Route path="/" element={<Contents />} />
                        <Route path="*" element={<Contents />} />
                        <Route path="resources" element={<Resources />} />
                    </Routes>
                </div>
            </div>
        )
    }
}

// Create the plugin that provides our layout component
const ApiBaseLayoutPlugin = () => {
    return {
        components: {
            ApiBaseLayout: ApiBaseLayout,
            operations: ApiOperations,
            Menu: SideMenu,
            operation: ApiOperation,
            OperationSummary: ApiOperationSummary,
            Resources: Resources,
            contents: ApiContent,
            RequestBody: ApiRequestBody,
            parameters: ApiParameters
        },
        wrapComponents: {
            OperationTag: (Original, system) => (props) => {
                return <ApiOperationTag operationTag={Original} operationTagProps={props} {...props}/>
            }
        }
    }
}

export default class SwaggerContainer extends Component {

    swaggerPlugins = [
        ApiBaseLayoutPlugin
    ]

    constructor(props) {
        super(props);
    }

    render() {
        return <SwaggerUI
            url = "openapi?format=json"
            plugins = {this.swaggerPlugins}
            layout = "ApiBaseLayout"
            onComplete = { () => {
                let menuItem = document.getElementsByClassName("api-menu-tag");
                let i;
    
                for (i = 0; i < menuItem.length; i++) {
                    menuItem[i].addEventListener("click", function() {
                        this.classList.toggle("active");
                        let content = this.nextElementSibling;
                        if (content != null && content.style != null) {
                            if (content.style.display === "block") {
                                content.style.display = "none";
                            } else {
                                content.style.display = "block";
                            }
                        } else {
                            // clicking on the non-expandable leftnav menu mimicks clicking on the a link inside of it
                            const myLink = this.getElementsByTagName('a');
                            if (myLink.length>0 && myLink[0].hasAttribute('href')){
                                myLink[0].click();
                            }
                        }
                    });
                }                
            }}
        />
    }
}